import React from 'react'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { isEmpty } from 'lodash'

import Footer from '../../components/Footer'
import ProfilesList from '../../components/ProfilesList'
import { Icons } from '../../components/Icons'
import iconPencil from '../../assets/icons/pencil.svg'
import iconArrowBack from '../../assets/icons/arrowBack.svg'
import iconLogOut from '../../assets/icons/logOut.svg'
import iconNext from '../../assets/icons/next.svg';
import iconExternalLink from '../../assets/icons/outLink.svg';

export default function ProfileContainer(props) {
  const {
    handleOnCancelSubscription,
    handleOnSelectProfile,
    navigate,
    logout,
    plan,
    status,
    subscriber,
    subscriber: {
      idp
    },
    userData: {
      email
    },
    useImageCDN
  } = props

  return (
    <main className='appWrap'>
      <Button variant='contained' className='medium goBack' onClick={() => navigate('/inicio')} startIcon={<Icons path={iconArrowBack} />}>Volver</Button>
      <div className='mainWidthProfile noHeroWrap'>
        {!isMobile &&
          <section className='profileSection'>
            <header className='mediumHeader'>
              <h3>Perfiles</h3>
            </header>
            <ProfilesList
              navigate={navigate}
              handleOnSelectProfile={handleOnSelectProfile}
              useImageCDN={useImageCDN}
            />
            <Link to='/cuenta/seleccionar'>
              <Button variant='contained' startIcon={<Icons path={iconPencil} />}>
                Editar perfiles
              </Button>
            </Link>
          </section>
        }
        <section className='profileSection'>
          <header className='mediumHeader'>
            <h3>Información de mi plan</h3>
          </header>
          <div className='profileTextRow'>
            {isEmpty(idp) && isEmpty(subscriber?.idp_id) ? (
              <>
                <p>
                  {plan.name || 'Plan Básico'}
                </p>
                <div className='profileLinkHorizontal'>
                  {!isEmpty(plan) && <Link to={`plan`}>Administrar mi plan <Icons path={iconNext} /></Link>}
                  {plan.id !== process.env.REACT_APP_MS_PLAN_BASICO && plan.id !== process.env.REACT_APP_MS_PLAN_CORPORATIVO && status !== 'CANCELED' &&
                    <Button onClick={handleOnCancelSubscription}>Cancelar mi plan <Icons path={iconExternalLink} /></Button>
                  }
                </div>
              </>
            ) :
              <div className='disclaimerProfile'>
                <img src={idp?.imageUrl} alt={idp?.name || ''} />
                <p>La administración de este plan está asociada a tu proveedor{`${idp?.name ? ` ${idp.name}.` : '.'}`}</p>
              </div>
            }
          </div>
        </section>
        {isEmpty(idp) && isEmpty(subscriber?.idp_id) &&
          <section className='profileSection'>
            <header className='mediumHeader'>
              <h3>Datos de cuenta</h3>
            </header>
            <div className='profileTextRow'>
              <p>Correo: <span>{email}</span></p>
            </div>
          </section>
        }
        <section className='profileSection'>
          <Button variant='contained' startIcon={<Icons path={iconLogOut} />} onClick={logout}>Cerrar Sesión</Button>
        </section>
        <div className='rowXl'>
          <p className='appVersion'>Mega GO v{process.env.REACT_APP_VERSION}</p>
        </div>
      </div>
      <Footer />
    </main>
  )
}