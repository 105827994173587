import { handleActions } from 'redux-actions'

import {
  GET_SUBSCRIPTION_START,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_ERROR,
  GET_SUBSCRIPTION_FINALLY,
  SET_SUBSCRIPTIONS_START,
  SET_SUBSCRIPTIONS_SUCCESS,
  SET_SUBSCRIPTIONS_ERROR,
  CHANGE_SUBSCRIPTION_START,
  CHANGE_SUBSCRIPTION_SUCCESS,
  CHANGE_SUBSCRIPTION_ERROR,
  VALIDATE_SUBSCRIPTION_START,
  VALIDATE_SUBSCRIPTION_SUCCESS,
  VALIDATE_SUBSCRIPTION_ERROR,
  VALIDATE_SUBSCRIPTION_FINALLY,
  CANCEL_SUBSCRIPTION_START,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_ERROR,
  CANCEL_SUBSCRIPTION_FINALLY,
} from '../../redux/actions'

const initialState = {
  data: {},
  error: null,
  isLoading: true,
  urlToPaySubscription: '',
  transaction: {},
  isLoadingCancel: false,
  errorCancel: null,
  dataCancel: null,
  isFreePlan: true,
  subscriptionsPlanIds: [],
  subscriber: {}
}

export default handleActions({
  [GET_SUBSCRIPTION_START]: state => ({
    ...state,
    isLoading: true,
    error: false
  }),
  [GET_SUBSCRIPTION_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload.subscription,
    isFreePlan: action.payload.isFreePlan,
    subscriptionsPlanIds: action.payload.subscriptionsPlanIds,
    subscriber: action.payload.subscriber
  }),
  [GET_SUBSCRIPTION_ERROR]: (state, action) => ({
    ...state,
    error: action.payload
  }),
  [GET_SUBSCRIPTION_FINALLY]: state => ({
    ...state,
    isLoading: false
  }),
  [SET_SUBSCRIPTIONS_START]: state => ({
    ...state,
    isLoading: true,
    error: false
  }),
  [SET_SUBSCRIPTIONS_SUCCESS]: (state, action) => ({
    ...state,
    urlToPaySubscription: action.payload
  }),
  [SET_SUBSCRIPTIONS_ERROR]: (state, action) => ({
    ...state,
    error: action.payload,
    isLoading: false
  }),
  [CHANGE_SUBSCRIPTION_START]: state => ({
    ...state,
    isLoading: true,
    error: false
  }),
  [CHANGE_SUBSCRIPTION_SUCCESS]: (state, action) => ({
    ...state,
    urlToPaySubscription: action.payload
  }),
  [CHANGE_SUBSCRIPTION_ERROR]: (state, action) => ({
    ...state,
    error: action.payload,
    isLoading: false
  }),
  [VALIDATE_SUBSCRIPTION_START]: state => ({
    ...state,
    isLoading: true,
    error: false
  }),
  [VALIDATE_SUBSCRIPTION_SUCCESS]: (state, action) => ({
    ...state,
    transaction: action.payload
  }),
  [VALIDATE_SUBSCRIPTION_ERROR]: (state, action) => ({
    ...state,
    error: action.payload
  }),
  [VALIDATE_SUBSCRIPTION_FINALLY]: state => ({
    ...state,
    isLoading: false
  }),
  [CANCEL_SUBSCRIPTION_START]: state => ({
    ...state,
    isLoadingCancel: true,
    error: false
  }),
  [CANCEL_SUBSCRIPTION_SUCCESS]: (state, action) => ({
    ...state,
    dataCancel: action.payload,
    isLoadingCancel: false
  }),
  [CANCEL_SUBSCRIPTION_ERROR]: (state, action) => ({
    ...state,
    errorCancel: action.payload
  }),
  [CANCEL_SUBSCRIPTION_FINALLY]: state => ({
    ...state
  }),
}, initialState)