import { call, put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import { isEmpty } from 'lodash'

import {
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_START,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_ERROR,
  GET_SUBSCRIPTION_FINALLY,
  SET_SUBSCRIPTIONS,
  SET_SUBSCRIPTIONS_START,
  SET_SUBSCRIPTIONS_SUCCESS,
  SET_SUBSCRIPTIONS_ERROR,
  CHANGE_SUBSCRIPTION,
  CHANGE_SUBSCRIPTION_START,
  CHANGE_SUBSCRIPTION_SUCCESS,
  CHANGE_SUBSCRIPTION_ERROR,
  VALIDATE_SUBSCRIPTION,
  VALIDATE_SUBSCRIPTION_START,
  VALIDATE_SUBSCRIPTION_SUCCESS,
  VALIDATE_SUBSCRIPTION_ERROR,
  VALIDATE_SUBSCRIPTION_FINALLY,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_START,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_ERROR,
  CANCEL_SUBSCRIPTION_FINALLY,
} from '../../redux/actions'

function* GetSubscriptionAction({ payload: { keycloak } }) {
  yield put(GET_SUBSCRIPTION_START())
  let token = keycloak.token
  const userInfo = yield call(keycloak.loadUserInfo)

  try {
    const isTokenUpdated = yield call(keycloak.updateToken, 5)
    token = isTokenUpdated ? keycloak.token : token
    let subscription = {}
    let isFreePlan = true
    let subscriptionsPlanIds = []

    const headers = {
      headers: { 'authorization': `Bearer ${token}` },
      timeout: 10000
    }

    let { status, data: subscriber } = yield call(axios.get,
      `${process.env.REACT_APP_SUBSCRIPTIONS_API_URL}subscribers/${userInfo.sub}`,
      headers
    )

    if (status === 204) {
      const newSubs = {
        uid: userInfo.sub,
        personal_id: '11111111-1',
        email: userInfo.email,
        name: userInfo.name,
        username: userInfo.name,
        first_name: userInfo.first_name || 'N',
        last_name: userInfo.last_name || 'N',
        country_code: '56',
      }

      const { status: createdStatus, data: createdSubscriber } = yield call(axios.post,
        `${process.env.REACT_APP_SUBSCRIPTIONS_API_URL}subscribers`,
        newSubs,
        headers
      )

      if (createdStatus === 201) {
        subscriber = createdSubscriber
      }
    }

    const { data } = yield call(axios.get,
      `${process.env.REACT_APP_SUBSCRIPTIONS_API_URL}subscribers/${userInfo.sub}/subscriptions?active=true`,
      headers
    )

    subscriptionsPlanIds = data.map(x => x.plan_id)

    const activeAndPaidSubscription = data.filter(sub => (
      sub.active && sub.plan_id !== process.env.REACT_APP_MS_PLAN_BASICO && sub.Plan.type === 'SUBSCRIPTION'
    ))

    if (isEmpty(activeAndPaidSubscription)) {
      const activeAndBasicSubscription = data.filter(sub => (
        sub.active && sub.plan_id === process.env.REACT_APP_MS_PLAN_BASICO
      ))
      subscription = activeAndBasicSubscription[0]
    } else {
      subscription = activeAndPaidSubscription[0]
    }

    if (isEmpty(subscription) || isEmpty(activeAndPaidSubscription)) {
      isFreePlan = true
    } else {
      isFreePlan = false
    }

    let subscriberAux = subscriber
    if (!isEmpty(subscriber?.idp_id)) {
      try {
        const { data: idp } = yield call(axios.get,
          `${process.env.REACT_APP_SUBSCRIPTIONS_API_URL}idp/${subscriber.idp_id}`,
          headers
        )

        !isEmpty(idp) && (
          subscriberAux = {
            ...subscriber,
            idp
          }
        )
      } catch { }
    }

    yield put(GET_SUBSCRIPTION_SUCCESS({ subscription, isFreePlan, subscriptionsPlanIds, subscriber: subscriberAux }))
  } catch (error) {
    yield put(GET_SUBSCRIPTION_ERROR(error.message))
  } finally {
    yield put(GET_SUBSCRIPTION_FINALLY())
  }
}

function* SetSubscriptionAction({ payload: { keycloak, planId, isSubscription } }) {
  yield put(SET_SUBSCRIPTIONS_START())
  let token = keycloak.token
  const sub = keycloak ? keycloak.subject : null

  try {
    const isTokenUpdated = yield call(keycloak.updateToken, 5)
    token = isTokenUpdated ? keycloak.token : token

    const headers = {
      headers: { 'authorization': `Bearer ${token}` },
      timeout: 10000
    }

    const url = isSubscription
      ? `${process.env.REACT_APP_SUBSCRIPTIONS_API_URL}subscribers/${sub}/subscriptions`
      : `${process.env.REACT_APP_SUBSCRIPTIONS_API_URL}subscribers/${sub}/one-pay/`

    const { data: { url_to_pay_subscription: urlToPaySubscription } } = yield call(axios.post,
      url,
      {
        plan_id: planId,
        url_callback: process.env.REACT_APP_SUBSCRIPTIONS_API_URL_CALLBACK
      },
      headers
    )

    !isEmpty(urlToPaySubscription) && window.location.replace(urlToPaySubscription)
    yield put(SET_SUBSCRIPTIONS_SUCCESS(urlToPaySubscription))
  } catch (error) {
    yield put(SET_SUBSCRIPTIONS_ERROR(error.message))
  }
}

function* ChangeSubscriptionAction({ payload: { keycloak, planId, subscriptionId } }) {
  yield put(CHANGE_SUBSCRIPTION_START())
  let token = keycloak.token
  const sub = keycloak ? keycloak.subject : null

  try {
    const isTokenUpdated = yield call(keycloak.updateToken, 5)
    token = isTokenUpdated ? keycloak.token : token

    const headers = {
      headers: { 'authorization': `Bearer ${token}` },
      timeout: 10000
    }

    const { data: { url_to_pay_subscription: urlToPaySubscription } } = yield call(axios.post,
      `${process.env.REACT_APP_SUBSCRIPTIONS_API_URL}subscribers/${sub}/subscriptions/${subscriptionId}/change-plan`,
      {
        plan_id: planId,
        url_callback: process.env.REACT_APP_SUBSCRIPTIONS_API_URL_CALLBACK
      },
      headers
    )

    !isEmpty(urlToPaySubscription) && window.location.replace(urlToPaySubscription)
    yield put(CHANGE_SUBSCRIPTION_SUCCESS(urlToPaySubscription))
  } catch (error) {
    yield put(CHANGE_SUBSCRIPTION_ERROR(error.message))
  }
}

function* ValidateSubscriptionAction({ payload: { keycloak, transactionId } }) {
  yield put(VALIDATE_SUBSCRIPTION_START())
  let token = keycloak.token

  try {
    const isTokenUpdated = yield call(keycloak.updateToken, 5)
    token = isTokenUpdated ? keycloak.token : token

    const headers = {
      headers: { 'authorization': `Bearer ${token}` },
      timeout: 10000
    }

    const { data } = yield call(axios.get,
      `${process.env.REACT_APP_SUBSCRIPTIONS_API_URL}transactions/${transactionId}`,
      headers
    )

    yield put(VALIDATE_SUBSCRIPTION_SUCCESS(data))
  } catch (error) {
    yield put(VALIDATE_SUBSCRIPTION_ERROR(error.message))
  } finally {
    yield put(VALIDATE_SUBSCRIPTION_FINALLY())
  }
}

function* CancelSubscriptionAction({ payload: { keycloak, subscription_id, subscriptor_id, url_callback } }) {
  yield put(CANCEL_SUBSCRIPTION_START())
  let token = keycloak.token

  try {
    const isTokenUpdated = yield call(keycloak.updateToken, 5)
    token = isTokenUpdated ? keycloak.token : token

    const headers = {
      headers: { 'authorization': `Bearer ${token}` },
      timeout: 10000
    }

    const { data } = yield call(axios.put,
      `${process.env.REACT_APP_SUBSCRIPTIONS_API_URL}subscribers/${subscriptor_id}/subscriptions/${subscription_id}/cancel`,
      {
        url_callback: url_callback
      },
      headers
    )

    !isEmpty(data.cancelUrl)
      ? window.location.replace(data.cancelUrl)
      : yield put(CANCEL_SUBSCRIPTION_SUCCESS(data))
  } catch (error) {
    yield put(CANCEL_SUBSCRIPTION_ERROR(error.message))
  } finally {
    yield put(CANCEL_SUBSCRIPTION_FINALLY())
  }
}

export default function* actionsWatcher() {
  yield takeLatest(GET_SUBSCRIPTION, GetSubscriptionAction)
  yield takeLatest(SET_SUBSCRIPTIONS, SetSubscriptionAction)
  yield takeLatest(CHANGE_SUBSCRIPTION, ChangeSubscriptionAction)
  yield takeLatest(VALIDATE_SUBSCRIPTION, ValidateSubscriptionAction)
  yield takeLatest(CANCEL_SUBSCRIPTION, CancelSubscriptionAction)
}