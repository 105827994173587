import React, { useState, useEffect } from 'react'
import Slider from '@mui/material/Slider'

import { timerFormat } from '../../utils/timerFormat'

function SliderWithPreview({
  vttContent,
  played,
  baseUrlImage,
  onSeek,
  onSeekMouseDown,
  onSeekMouseUp,
  duration
}) {
  const [previewUrl, setPreviewUrl] = useState('')
  const [mouseOverPosition, setMouseOverPosition] = useState(0)
  const [parsedVtt, setParsedVtt] = useState(null)
  const [xywh, setXywh] = useState('')
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    if (vttContent) {
      const list = parseVTT(vttContent)
      if (list?.length) {
        setParsedVtt(list)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vttContent])

  const handleMouseOverSlider = (event) => {
    const sliderRail = document.querySelector('.MuiSlider-rail')
    const sliderRailRect = sliderRail.getBoundingClientRect()
    const position = event.clientX - sliderRailRect.left
    const sliderWidth = sliderRailRect.width
    const percentage = position / sliderWidth
    if (percentage < 0 || percentage > 1) return 0
    updatePreview(percentage)
  }

  const updatePreview = (percentage) => {
    const parsedImg = parseVTTForImageUrl(parsedVtt, percentage)
    if (parsedImg) {
      const { imageUrl, xywhParams } = parsedImg
      setMouseOverPosition(percentage * 100)
      setPreviewUrl(imageUrl)
      setXywh(xywhParams)
    }
  }

  function parseVTT(vttContent) {
    const lines = vttContent.split(/\r?\n/).filter(f => f)
    const imagesData = []

    let currentImageData = {
      startTime: null,
      endTime: null
    }

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim()
      if (!line || !isValidVTTLine(line)) {
        continue
      }
      if (line.includes('-->')) {
        const [startTime, endTime] = line.split('-->').map(time => time.trim())
        currentImageData['startTime'] = convertTimeToSeconds(startTime)
        currentImageData['endTime'] = convertTimeToSeconds(endTime)
      }
      else if (line.startsWith('./') || line.startsWith('/') || line.startsWith('http')) {
        if (currentImageData) {
          currentImageData.imageUrl = line.replace(/^\.\//, '').replace(/#.*/, '')
          const xywhMatch = line.match(/#xywh=(\d+),(\d+),(\d+),(\d+)/)
          if (xywhMatch) {
            const [, x, y, w, h] = xywhMatch
            currentImageData.xywhParams = [parseInt(x), parseInt(y), parseInt(w), parseInt(h)]
          }
          imagesData.push(currentImageData)
        }
        currentImageData = {
          startTime: null,
          endTime: null,
          imageUrl: null,
          xywhParams: null
        }
      }
    }
    return imagesData
  }

  function parseVTTForImageUrl(parsedVtt, percValue) {
    const percValueSeconds = calculateTimeInSeconds(percValue)

    return parsedVtt?.length ? parsedVtt.find(imageData => {
      return percValueSeconds >= imageData.startTime && percValueSeconds <= imageData.endTime
    }) : null
  }

  function isValidVTTLine(line) {
    return line.trim() !== ''
  }

  function convertTimeToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(parseFloat)
    return hours * 3600 + minutes * 60 + seconds
  }

  function calculateTimeInSeconds(sliderValue) {
    return sliderValue * duration
  }

  function getContainerStyle(imageUrl, positionParams, position) {
    if (positionParams) {
      return {
        backgroundImage: `url(${baseUrlImage}/${imageUrl.split('#')[0]})`,
        backgroundPosition: `-${positionParams[0]}px -${positionParams[1]}px`,
        width: `${positionParams[2]}px`,
        height: `${positionParams[3]}px`,
        position: 'absolute',
        top: '-100px',
        left: `calc(${position}% - 50px)`,
        border: '2px solid white',
        borderRadius: '8px',
        transform: 'scale(1.5)',
        pointerEvents: 'none',
        opacity: isHovered ? 1 : 0,
        transition: 'opacity 0.3s ease',
      }
    }
    return {}
  }

  const handleMouseEnterSlider = () => {
    setIsHovered(true)
  }

  const handleMouseLeaveSlider = () => {
    setIsHovered(false)
  }

  return (
    <div style={{ position: 'relative' }}>
      {previewUrl && xywh &&
        <div
          style={getContainerStyle(previewUrl, xywh, mouseOverPosition)}
          className='thumb-video-image'>
          <span
            className='thumb-video-image-time'
            style={{
              bottom: '-20px',
              position: 'absolute',
              width: '100%',
              textAlign: 'center',
              transform: 'scale(.7)'
            }}
          >
            {
              timerFormat((duration * mouseOverPosition) / 100)
            }
          </span>
        </div>
      }
      <Slider
        aria-label='Slider'
        onMouseMove={handleMouseOverSlider}
        min={0}
        max={100}
        value={played}
        onChange={onSeek}
        onMouseDown={onSeekMouseDown}
        onChangeCommitted={onSeekMouseUp}
        onMouseEnter={handleMouseEnterSlider}
        onMouseLeave={handleMouseLeaveSlider}
        className='timeSlider'
      />
    </div>
  )
}

export default SliderWithPreview
