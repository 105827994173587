import { createAction } from 'redux-actions';

export const GET_SUBSCRIPTION = createAction('GET_SUBSCRIPTION');
export const GET_SUBSCRIPTION_START = createAction('GET_SUBSCRIPTION_START');
export const GET_SUBSCRIPTION_SUCCESS = createAction('GET_SUBSCRIPTION_SUCCESS');
export const GET_SUBSCRIPTION_ERROR = createAction('GET_SUBSCRIPTION_ERROR');
export const GET_SUBSCRIPTION_FINALLY = createAction('GET_SUBSCRIPTION_FINALLY');
export const SET_SUBSCRIPTIONS = createAction('SET_SUBSCRIPTIONS');
export const SET_SUBSCRIPTIONS_START = createAction('SET_SUBSCRIPTIONS_START');
export const SET_SUBSCRIPTIONS_SUCCESS = createAction('SET_SUBSCRIPTIONS_SUCCESS');
export const SET_SUBSCRIPTIONS_ERROR = createAction('SET_SUBSCRIPTIONS_ERROR');
export const CHANGE_SUBSCRIPTION = createAction('CHANGE_SUBSCRIPTION');
export const CHANGE_SUBSCRIPTION_START = createAction('CHANGE_SUBSCRIPTION_START');
export const CHANGE_SUBSCRIPTION_SUCCESS = createAction('CHANGE_SUBSCRIPTION_SUCCESS');
export const CHANGE_SUBSCRIPTION_ERROR = createAction('CHANGE_SUBSCRIPTION_ERROR');
export const VALIDATE_SUBSCRIPTION = createAction('VALIDATE_SUBSCRIPTION');
export const VALIDATE_SUBSCRIPTION_START = createAction('VALIDATE_SUBSCRIPTION_START');
export const VALIDATE_SUBSCRIPTION_SUCCESS = createAction('VALIDATE_SUBSCRIPTION_SUCCESS');
export const VALIDATE_SUBSCRIPTION_ERROR = createAction('VALIDATE_SUBSCRIPTION_ERROR');
export const VALIDATE_SUBSCRIPTION_FINALLY = createAction('VALIDATE_SUBSCRIPTION_FINALLY');
export const CANCEL_SUBSCRIPTION = createAction('CANCEL_SUBSCRIPTION');
export const CANCEL_SUBSCRIPTION_START = createAction('CANCEL_SUBSCRIPTION_START');
export const CANCEL_SUBSCRIPTION_SUCCESS = createAction('CANCEL_SUBSCRIPTION_SUCCESS');
export const CANCEL_SUBSCRIPTION_ERROR = createAction('CANCEL_SUBSCRIPTION_ERROR');
export const CANCEL_SUBSCRIPTION_FINALLY = createAction('CANCEL_SUBSCRIPTION_FINALLY');